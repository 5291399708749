.App {
  text-align: center;
}


.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}


.telemetry-container {
  text-align: left; 
  margin-left: 2x;
  margin-right: 2px;
  overflow-y: auto;
  width: 100%;
  height: 100%;
  background: 'white';
}


.leaflet-container {
  width: 100%; 
  height: 100%;
}

